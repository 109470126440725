import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { IApiPaths, setApiPaths } from './shared/lib/apiConstants';

const Kundeoversikt = lazy(() => import('./kunder/Kundeoversikt/Kundeoversikt'));
const Kundeside = lazy(() => import('./kunder/Kundeside/Kundeside'));
const LeggTilKunde = lazy(() => import('./kunder/LeggTilKunde'));
const Kundedetaljer = lazy(() => import('./kunder/kundedetaljer/Kundedetaljer'));
const RedigerKundedetaljer = lazy(() => import('./kunder/kundedetaljer/RedigerKundedetaljer'));
const GenererteDokumenter = lazy(() => import('./kunder/GenererteDokumenter'));
const Veilederoversikt = lazy(() => import('./veileder/Veilederoversikt/Veilederoversikt'));
const VeilederStart = lazy(() => import('./veileder/VeilederStart'));
const VeilederVelgKunde = lazy(() => import('./veileder/VeilederVelgKunde'));
const Veileder = lazy(() => import('./veileder/Veileder'));
const KundeImport = lazy(() => import('./kunder/Kundeimport/ImportKundeList'));
const VeilederOppsummering = lazy(() => import('./veileder/VeilederOppsummering'));
const SendEpost = lazy(() => import('./kunder/Sendepost/SendEpost'));
const SendEpostDigitalSignering = lazy(() => import('./kunder/Sendepost/SendEpostDigitalSignering'));
const EndreKundeAnsvarlig = lazy(() => import('./kunder/Kundeansvarlig/EndreKundeAnsvarlig'));
const MalOppsummering = lazy(() => import('./veileder/MalOppsummering'));

export interface IAppProps {
    basename: string;
    apiPaths: IApiPaths;
    isExternalApp: boolean;
}
const toastOptions = {
    className: '',
    duration: 7000,
    style: {
        padding: '16px',
        color: '#000',
        backgroundColor: '#F9CBCB',
    },
};

const App = (props: IAppProps) => {
    setApiPaths(props.apiPaths);
    window.isExternalApp = false;

    return (
        <Suspense
            fallback={
                <div className="container">
                    <h1>Vennligst vent...</h1>
                </div>
            }
        >
            <Router basename={props.basename}>
                <RouteApp />
            </Router>

            <Toaster position="top-center" reverseOrder toastOptions={toastOptions} />
        </Suspense>
    );
};

export const RouteApp = () => {
    return (
        <Routes>
            <Route path="/" element={<Kundeoversikt />} />
            <Route path="/kunder" element={<Kundeoversikt />} />
            <Route path="/kunder/:kundeId" element={<Kundedetaljer />} />
            <Route path="/endre-kunde-ansvarlig" element={<EndreKundeAnsvarlig />} />
            <Route path="/kunder/send-epost/:kundeId/:dokumentId/:tittel" element={<SendEpost />} />
            <Route path="/kunder/send-epost-signering/:kundeId/:dokumentId/:tittel" element={<SendEpostDigitalSignering />} />
            <Route path="/kunder/:kundeId/rediger" element={<RedigerKundedetaljer />} />
            <Route path="/kunder/:kundeId/kundeside" element={<Kundeside />} />
            <Route path="/kunder/:kundeId/velg-veileder" element={<Veilederoversikt />} />
            <Route path="/kunder/:kundeId/genererte-dokumenter" element={<GenererteDokumenter />} />
            <Route path="/legg-til-kunde" element={<LeggTilKunde />} />
            <Route path="/opprett-kunde" element={<RedigerKundedetaljer />} />
            <Route path="/veiledere" element={<Veilederoversikt />} />
            <Route path="/mal/:veilederId" element={<MalOppsummering />} />
            <Route path="/mal/:veilederId/kunde/:kundeId" element={<MalOppsummering />} />
            <Route path="/veileder/:veilederId" element={<VeilederStart />} />
            <Route path="/veileder/:veilederId/velg-kunde" element={<VeilederVelgKunde />} />
            <Route path="/kunder/:kundeId/veileder/:veilederId/" element={<VeilederStart />} />
            <Route path="/kunder/:kundeId/veileder/:veilederId/steg" element={<Veileder />} />
            <Route path="/kunder/:kundeId/veileder/:veilederId/oppsummering" element={<VeilederOppsummering />} />
            <Route path="/import-kunde/:orgnr" element={<KundeImport />} />
        </Routes>
    );
};

export default App;
